(function () {
  const WebFontConfig = {
    google: {
      families: [
        "Kanit:ital,wght@0,300;0,400;0,700;0,800;1,300;1,400;1,700;1,800:latin-ext"
      ]
    }
  };

  let wf = document.createElement("script");
  wf.src =
    ("https:" == document.location.protocol ? "https" : "http") +
    "://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js";
  wf.type = "text/javascript";
  wf.async = "true";
  let s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(wf, s);
})();



const initPlaceholder = function (e) {
  //console.log(e);
  // setPlaceholder(e.currentTarget);
}

const movePlaceholder = function (e) {
  // console.log(e);
}


const setPlaceholder = function (el) {

  const elInfo = document.createElement("div");
  elInfo.classList.add("js-holder-info");
  el.parentNode.insertBefore(elInfo, el);
  elInfo.appendChild(el);

  const elTitle = el.getAttribute("title");
  const elInfoSpan = document.createElement("span");
  elInfoSpan.classList.add("js-holder-info__span");
  elInfo.insertBefore(elInfoSpan, el);
  elInfoSpan.innerHTML = elTitle;

  if (el.value.length != 0) {
    elInfo.classList.add("is-value");
    elInfo.classList.remove("is-focussed");
  }

  el.addEventListener('focus', () => {
    elInfo.classList.add("is-focussed");
    elInfo.classList.remove("is-value");
  });

  el.addEventListener('blur', (e) => {
    if (el.value.length != 0) {
      elInfo.classList.add("is-value");
      elInfo.classList.remove("is-focussed");
    }
    else
      elInfo.classList.remove("is-focussed", "is-value");
  }
  );

}




document.addEventListener("DOMContentLoaded", function () {

  let scrolled = window.pageYOffset || document.documentElement.scrollTop;

  const jsParallax = document.querySelectorAll(".js-parallax");
  if (jsParallax) {
    window.addEventListener('scroll', e => {
      scrolled = window.pageYOffset || document.documentElement.scrollTop;

      jsParallax.forEach(el => {
        el.style.top = (-(scrolled * 0.2) + 'px');
      });

    });
  }

  const jsPlaceholder = document.querySelectorAll(".js-placeholder");
  if (jsPlaceholder) {

    jsPlaceholder.forEach(el => setPlaceholder(el));
  }

  // function smoothscroll() {
  //   if (scrolled > 0) {
  //     window.requestAnimationFrame(smoothscroll);
  //     window.scrollTo(0, scrolled - (scrolled / 50));
  //   }
  // };

  /*scroll top*/
  const goTop = document.querySelector(".js-go-top");
  if (goTop) goTop.addEventListener("click", e => {
    //smoothscroll();
    //window.scrollTo({ top: 0, behavior: 'smooth' });

    const scrollToTop = window.setInterval(function () {

      if (scrolled > 0) {
        window.scrollTo(0, scrolled - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);

  });



  /*menu switcher*/
  const menuSwitcher = document.querySelector('.js-menu-switcher');
  const menu = document.querySelector('.js-menu');
  const body = document.querySelector('body');
  if (menuSwitcher && menu) {

    menuSwitcher.addEventListener('click', e => {
      body.classList.toggle('js-menu-active');

      menuSwitcher.classList.toggle('js-menu-switcher-open');
      menu.classList.toggle('js-menu-open');
    });
  }


  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {

  // }

  //cookies
  (function () {
    const jsCookiesPopup = document.querySelector(".js-cookies-popup");
    const jsCookiesClose = document.querySelector(".js-cookies-close");
    const jsCookiesOk = document.querySelector(".js-cookies-ok");
    const jsCookiesNo = document.querySelector(".js-cookies-no");
    const jsCookiesPopupBg = document.querySelector(".js-cookies-popup-bg");

    function removePopup() {
      jsCookiesPopupBg.parentNode.removeChild(jsCookiesPopupBg);
      jsCookiesPopup.classList.add('c-cookies-popup--hide');

      setTimeout(jsCookiesPopup.parentNode.removeChild(jsCookiesPopup), 5000);
      console.log('remove');
    }

    function setCookies() {
      console.log('set');

    }

    function akceptPopup(e) {
      removePopup();
      setCookies();
      e.currentTarget.removeEventListener('click', akceptPopup);
    }
    //add events
    if (jsCookiesPopup) {
      jsCookiesClose.addEventListener('click', akceptPopup);
      jsCookiesOk.addEventListener('click', akceptPopup);
      jsCookiesNo.addEventListener('click', removePopup);
    }
  })();


  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }


  initForm();



});


function initForm() {

  const helloForm = document.querySelector('.js-hello-form');
  helloForm.querySelector('.js-hello-submit').addEventListener('click', e => {
    e.preventDefault();

    checkForm(helloForm) && sendForm(helloForm);



  });
}


function checkForm(helloForm) {

  helloForm.querySelectorAll('.js-holder-info').forEach(el => el.classList.remove('is-error-value'));
  const validations = {

    required: function (value) {
      return value !== '';
    },
    phone: function (value) {
      const testPhone = new RegExp(/^([+,0-9]{3})?[ ,\.]{0,1}[0-9-, ]{8,12}$/);
      return testPhone.test(value);
    },
    email: function (value) {
      const testEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
      return testEmail.test(value);
    }
  }

  let isFormError = false;
  const
    helloName = helloForm.querySelector('[name="hello-name"]'),
    helloEmail = helloForm.querySelector('[name="hello-email"]'),
    helloPhone = helloForm.querySelector('[name="hello-phone"]'),
    helloAdress = helloForm.querySelector('[name="hello-adress"]'),
    helloMessage = helloForm.querySelector('[name="hello-message"]');

  if (!validations.required(helloName.value)) {
    isFormError = true;
    helloName.parentNode.classList.add('is-error-value');
  }

  if (!validations.phone(helloPhone.value)) {
    isFormError = true;
    helloPhone.parentNode.classList.add('is-error-value');
  }

  if (!validations.email(helloEmail.value)) {
    isFormError = true;
    helloEmail.parentNode.classList.add('is-error-value');
  }

  if (!validations.required(helloMessage.value)) {
    isFormError = true;
    helloMessage.parentNode.classList.add('is-error-value');
  }

  return !isFormError;
}


function showAllert(text, isCorrect) {
  const helloFormInfo = document.querySelector('.js-hello-form-info');
  helloFormInfo.textContent = '';
  let classInfo = "c-alert__error";
  if (isCorrect) classInfo = "c-alert__correct";
  const spanHelloFormInfo = document.createElement("span");
  spanHelloFormInfo.classList.add(classInfo);
  helloFormInfo.appendChild(spanHelloFormInfo);
  spanHelloFormInfo.innerHTML = text;
}

function sendForm(helloForm) {

  showAllert('przetwarzanie', true);
  // const helloFormInfo = document.querySelector('.js-hello-form-info');
  // scrollToEl(helloFormInfo);

  const helloFormData = new FormData(helloForm);
  helloFormData.append("action", "send_form");

  axios({
    method: 'post',
    url: 'sendmail.php',
    data: helloFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(
    (response) => {

      const responseData = response.data;
      showAllert(responseData.info, responseData.data);
      if (responseData.errorEl) {
        for (const val in responseData.errorEl) {
          const el = helloForm.querySelector(`[name="${val}"]`);
          el.parentNode.classList.add('is-error-value');
        }

      } else helloForm.remove();


    },
    (error) => {
      showAllert("Błąd wewnętrzny formularza.", false);
    }
  );

}

// function scrollToEl(el) {
//   console.log(el);
//   let scrolledEl = el.scrollTop;
//   console.log(el.scrollLeft, el.scrollTop);
//   let scrolled = window.pageYOffset || document.documentElement.scrollTop;
//   const scrollToTop = window.setInterval(function () {

//     console.log(scrolled);
//     console.log(scrolledEl);
//     if (scrolled > scrolledEl) {
//       window.scrollTo(0, scrolled - 20);
//     } else {
//       window.clearInterval(scrollToTop);
//     }
//   }, 2000);
// }
